import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import DisplayContentHeader from "./DisplayContentHeader";
import { nanoid } from "nanoid";
import pluralize from "pluralize";
class AuthorsListTemplate extends React.Component {
  render() {
    const { data, title } = this.props;
    let { edges: posts } = data.allMarkdownRemark;
    const { frontmatter: site } = data.site;

    //console.log(site.layout.columns);

    let columns = 12;

    if (site && site.layout && site.layout.columns) {
      columns = 12 / site.layout.columns;
    }

    // remove all un-published

    //console.log(posts);
    posts = posts.filter(
      (posts) =>
        posts.node.frontmatter.flags === null ||
        (posts.node.frontmatter.flags && posts.node.frontmatter.flags.published)
    );

    let featured = posts.filter(
      (posts) =>
        posts.node.frontmatter.flags && posts.node.frontmatter.flags.featured
    );

    let regular = posts.filter(
      (posts) =>
        !posts.node.frontmatter.flags ||
        (posts.node.frontmatter.flags && !posts.node.frontmatter.flags.featured)
    );

    //console.log(featured);

    return (
      <div className="content">
        {title ? <DisplayContentHeader title={title} showColon={true} /> : null}
        {featured && featured.length ? (
          <>
            <DisplayContentHeader
              title={pluralize("Featured Author", featured.length)}
              showColon={true}
              size={4}
              weight="bold"
            />
          </>
        ) : null}
        <div className="columns is-multiline">
          {featured &&
            featured.map(({ node: post }) => (
              <div className={`column is-${columns}`} key={nanoid()}>
                <DisplayContentHeader
                  title={post.frontmatter.name}
                  slug={post.fields.slug}
                  weight="semibold"
                  size={4}
                  summary={post.frontmatter.summary}
                />
              </div>
            ))}
        </div>
        {regular && regular.length ? (
          <>
            <DisplayContentHeader
              title={pluralize("Additional Author", regular.length)}
              showColon={true}
              size={4}
              weight="bold"
            />
          </>
        ) : null}
        <div className="columns is-multiline">
          {regular &&
            regular.map(({ node: post }) => (
              <div className={`column is-${columns}`} key={nanoid()}>
                <DisplayContentHeader
                  title={post.frontmatter.name}
                  slug={post.fields.slug}
                  weight="normal"
                  showColon={false}
                  size={4}
                  summary={post.frontmatter.summary}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

AuthorsList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function AuthorsList() {
  return (
    <StaticQuery
      query={graphql`
        query AuthorsListQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___name] }
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
            }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  name
                  summary
                  flags {
                    featured
                    published
                  }
                }
              }
            }
          }
          site: markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "site" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              layout {
                columns
              }
            }
          }
        }
      `}
      render={(data, count) => <AuthorsListTemplate data={data} />}
    />
  );
}
